import Link from 'next/link';
import { useContext, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';


import Row from "@components/Modules/Utils/Row/Row";

import Logo from '@components/UI/Logo/Logo';
import { DarkModeContext } from '@contexts/darkMode';
import { renderMainMenu, renderMainMenuMobile } from "@helper/menu";
import { useTranslation } from "next-i18next";
import { NextRouter, useRouter } from 'next/router';
import SearchView from './SearchView';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './Header.module.scss';

import { MaterialUISwitch } from '@components/Modules/Utils/SwitchDarkMode/SwitchDarkMode';

export default function Header({ menus: { mainMenu, mainMenuSecondary }, langData }) {

  const { t } = useTranslation();
  const router: NextRouter = useRouter();

  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  const navRef = useRef(null);
  const [lang, setLang] = useState('')
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [searchViewActive, setSearchViewActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openLangSelector: boolean = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event) => {
    setAnchorEl(null);

    langData?.map((el) => {
      if( el.lang == event.target.innerText.toLowerCase() ) {
          setLang(el.lang);
          delete router.query.slug
          router.push(
            {
              pathname: el.path,
              query: {...router?.query}
            },'', {
              locale: el.lang
            }
          )
        }     
      })
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeSwitch = (event) => {
    setDarkMode(event.target.checked)
  };

  useEffect(() => {
    setLang(router.locale)
    if (typeof window !== 'undefined') {
      const header = document.querySelector("header");
      const menuSticky = () => {
        if (window.scrollY > header.offsetHeight * 1.5) {
          setSticky(true);
          document.querySelector("body").classList.add('navSticky');
        } else {
          setSticky(false);
          document.querySelector("body").classList.remove('navSticky')
        }
      };
      document.addEventListener("scroll", () => {
        menuSticky();
      });
    }
  }, [router.locale])

  useEffect(() => { setMobileOpen(false) }, [router.asPath])


  return (
    <>
      <header ref={navRef} className={`${styles.header} ${sticky ? styles.headerSticky : ''}`} data-fullscreen={searchViewActive}>
        <Container maxWidth={false} className={styles.header_top}>

          <Row spacing={20} justifyContent={{ xs: "space-between", xl: "flex-start" }} >
            <Grid
              item
              xs={6}
              lg={sticky ? 'auto' : 6}
              order={1}
              display='flex'
              alignItems='center'
            >
              <Box className={styles.logo} display='flex' alignItems='center'>
                  <Link href="/" aria-label="Logo Edison NEXT">
                    <Logo color="#fff" />
                  </Link>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              lg={sticky ? 'auto' : 6}
              display="flex"
              justifyContent={'flex-end'}
              gap={{ xs: 20, md: 40 }}
              alignItems='center'
              order={sticky ? 3 : 2}
              className={styles.formControlLabel}
            >

              <FormControlLabel
                label={<Typography variant="description">{t('header.darkMode')}</Typography>}
                labelPlacement="start"
                control={<MaterialUISwitch onChange={handleChangeSwitch} checked={darkMode} />}
              />
              {!searchViewActive &&
                <Box sx={{ display: { xs: 'block', xl: 'none' } }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { xl: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              }
              {!sticky && (
                <>
                  <Button
                    id="lang-selector"
                    aria-controls={openLangSelector ? 'lang-selector-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openLangSelector ? 'true' : undefined}
                    onClick={handleClick}
                    variant="linkwhite"
                    className={styles.langSelector}
                    endIcon={openLangSelector ? <ExpandLess /> : <ExpandMore />}
                    sx={{ display: { xs: 'none', xl: 'inline-flex' } }}
                  >
                    {lang}
                  </Button>
                  <Menu
                    id="lang-selector-menu"
                    anchorEl={anchorEl}
                    open={openLangSelector}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'lang-selector',
                    }}
                  >
                    {langData?.map((el: {lang: string, path: string}) => <MenuItem key={`${el.path}_${el.lang}`} onClick={handleClose}>{el.lang.toUpperCase()}</MenuItem>)}
                  </Menu>
                   
                  <Button
                    component={Link}
                    variant="contained"
                    color="secondary"
                    id="header-contact-us"
                    size="small"
                    href={t('contact_us_url')}
                    sx={{ display: { xs: 'none', xl: 'flex' } }}
                  >{t('header.button')}</Button>
                </>
              )}

            </Grid>

            {!searchViewActive && <Grid
              item
              xs={sticky ? 'auto' : 6}
              gap={{ xs: 20, md: 40 }}
              alignItems='center'
              className={`${styles.menu} ${styles.mainMenuPrimary}`}
              order={sticky ? 2 : 3}
              sx={{ display: { xs: 'none', xl: 'flex' } }}
            >
              {renderMainMenu(mainMenu)}

            </Grid>}

            {!searchViewActive && <Grid
              item
              xs={sticky ? 'auto' : 6}
              justifyContent={'flex-end'}
              gap={{ xs: 20, md: 40 }}
              alignItems='center'
              className={`${styles.menu} ${styles.mainMenuSecondary}`}
              order={sticky ? 2 : 4}
              sx={{
                display: { xs: 'none', xl: 'flex' },
                marginLeft: sticky ? 'auto' : 0
              }}
            >
              {renderMainMenu(mainMenuSecondary)}

              {!sticky &&
                <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => {
                      setSearchViewActive(!searchViewActive)
                      handleDrawerToggle()
                    }}
                    sx={{ mr: 2, }}
                    className={styles.noButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
              }

            </Grid>}

            {(!sticky && searchViewActive) && <Grid
              item
              xs={12}
              order={2}
            >
              <Row>
                <Grid item xs={11} />
                <Grid item xs={1} style={{
                  justifyContent: "flex-end",
                  display: "flex"
                }}>
                  <Button
                    variant='linkwhite'
                    className={styles.noButton}
                    onClick={ () => {
                      setSearchViewActive(!searchViewActive)
                      handleDrawerToggle()
                    }}>
                    <CloseIcon style={{
                      width: "40px",
                      height: "40px"
                    }} />
                  </Button>
                </Grid>
              </Row>
              <SearchView  />
            </Grid>}
          </Row>

        </Container>

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          SlideProps={{
            direction: 'left'
          }}
          ModalProps={{
            keepMounted: true,
            disableEnforceFocus: true
          }}
          sx={{
            display: { xs: 'block', xl: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <Box sx={{ textAlign: 'center' }} >
            <Container maxWidth={false} className={styles.header_drawer}>

              <Box className={styles.logo} display='flex' alignItems='center' justifyContent='space-between'>
                <Link href={'/'}>
                  <Logo />
                </Link>
                
                <div className={styles.searchIcon}>
                  <SearchIcon onClick={() => setSearchViewActive(!searchViewActive)} color='primary' />
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { xl: 'none' } }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Box>

              <Box sx={{ mt: 23 }}>
                {/* <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="input-with-icon-adornment">Cosa stai cercando?</InputLabel>
                  <Input
                    onClick={() => setSearchViewActive(!searchViewActive)}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon color='primary' />
                      </InputAdornment>
                    }
                  />
                </FormControl> */}
              </Box>

              <Box sx={{ mt: 23 }} className={styles.mainMenuPrimary}>
                <List>
                  {mobileOpen && renderMainMenuMobile(mainMenu, handleDrawerToggle, mobileOpen)}
                </List>
              </Box>
              <Divider />
              <Box>
                <List>
                  {mobileOpen && renderMainMenuMobile(mainMenuSecondary, handleDrawerToggle, mobileOpen)}
                </List>
              </Box>

              <Divider />

              <Row pt={27} pb={27} justifyContent={'flex-end'}>
                <Grid item xs={6} sx={{display: 'flex'}}>
                  <Button
                    id="lang-selector-mob"
                    aria-controls={openLangSelector ? 'lang-selector-menu-mobile' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openLangSelector ? 'true' : undefined}
                    onClick={handleClick}
                    variant="link"
                    endIcon={openLangSelector ? <ExpandLess /> : <ExpandMore />}
                    sx={{minWidth: 'auto'}}
                  >{lang}</Button>
                  <Menu
                    id="lang-selector-menu-mobile"
                    anchorEl={anchorEl}
                    open={openLangSelector}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'lang-selector-mob',
                    }}
                  >
                    {langData?.map((el: {lang: string, path: string}) => <MenuItem key={`${el.path}_${el.lang}`} onClick={handleClose}>{el.lang.toUpperCase()}</MenuItem>)}
                  </Menu>
                </Grid>
                <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                  <FormControlLabel
                    label={!sticky && <Typography variant="description">{t('header.darkMode')}</Typography>}
                    labelPlacement="start"
                    control={<MaterialUISwitch onChange={handleChangeSwitch} checked={darkMode} />}
                  />
                </Grid>
              </Row>

              <Button
                className={styles.buttonDrawer}
                id="header-mobile-contact-us"
                variant="contained"
                href={t('contact_us_url')}
              >{t('header.button')}</Button>

            </Container>

          </Box>
        </Drawer>
      </header>
    </>
  );
}
