import {absoluteUrl} from "@lib/utils";

export const getThumbnailUrl = (field) => {
    const thumbnail : string =  field && field.thumbnail && field.thumbnail.uri ? absoluteUrl(field.thumbnail.uri.url) : null;
    return thumbnail
};

export const getThumbnailAlt = (field) => {
  const thumbnail : string =  field?.thumbnail ? field?.thumbnail.resourceIdObjMeta.alt : null;
  return thumbnail
};


export const getDocumentUrl = (field) => {
  const document : string =  field?.field_media_document?.uri ? absoluteUrl(field.field_media_document.uri.url) : null;
  return document
};

export const getVideoUrl = (field) => {
  const video : string =  field?.field_media_video_file?.uri ? absoluteUrl(field.field_media_video_file.uri.url) : null;
  return video
};

// TODO: rivedere sulle card
export const PLACEHOLDER_IMAGE = {
  "node--implants": "/assets/images/img-placeholder-01.jpg",
  "node--article": "/assets/images/img-placeholder-01.jpg",
}