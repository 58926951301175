import { useState, useCallback, useRef } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import Row from "@components/Modules/Utils/Row/Row";
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import styles from './Header.module.scss';

import { useRouter } from 'next/router';
import { useTranslation } from "next-i18next";

export default function SearchView() {
  const { t } = useTranslation();
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [icon, setIcon] = useState(false);
  const inputRef = useRef(null);

  const handleChange = useCallback((e) => {
    const val = e.target.value;

    if (val.length >= 3) setIcon(true);
    else setIcon(false);
    
    setSearchQuery(val);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/${router.locale}/search/?sq=${searchQuery}`;
  }

  const wordChipClick = (val) => {
    setSearchQuery(val);
    setIcon(true);
    inputRef.current.children[0].focus();
  }

  const wordsChips: string[] = [t('search.chips.energia'), t('search.chips.smartcity'), t('search.chips.sostenibile'), t('search.chips.decarbonizzazione'), t('search.chips.idrogeno')]

  return <>

    <Row justifyContent="center">
      <Grid item xs={12} lg={8} >
        <Box>
            <FormControl variant="standard" fullWidth>
              <Input
                ref={inputRef}
                value={searchQuery}
                autoComplete="off"
                className={styles.searchField}
                id="search"
                color="secondary"
                onKeyUp={ (e) => { (e.key === 'Enter' || e.keyCode === 13) && searchQuery.length >= 3 ? handleSubmit(e) : null }}
                onChange={handleChange}
                placeholder={t('search.search')}
                endAdornment={
                  <InputAdornment position="end">
                    {icon ? <ArrowForwardIcon onClick={handleSubmit} /> : <SearchIcon />}
                  </InputAdornment>
                }
              />
            </FormControl>
        </Box>
      </Grid>
    </Row>
    <Row justifyContent="center">
      <Grid item xs={12} lg={8} className={styles.resultsContainer}>
        <Typography className={styles.chipsLabel} component="p" variant="overline">{t('search.mostWords')}</Typography>
        <Box className={styles.filterChips}>
          {wordsChips?.map((word:string) => (
              <Chip
                key={t(word)}
                className={styles.wordChip}
                onClick={() => wordChipClick(word)}
                label={t(word)}
                size="small"
              />
            ))
          }
        </Box>
      </Grid>
    </Row>
  </>
}