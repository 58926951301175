import React, { useState } from "react";
import Logo from '@components/UI/Logo/Logo';
import Link from "next/link";
import { useTranslation } from "next-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";


import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import Close from "@mui/icons-material/Close";

import styles from "@components/Common/Header/Header.module.scss";
import { MenuItemPrimary } from "@components/Common/Menu/MenuItemPrimary";

// TODO: Spostare sotto /components/menu
function MenuMobileElement({item, close, parentOpen }) {
  const { t } = useTranslation();
  const hasChild = item?.items?.length > 0 ? true : false;
  const [open, setOpen] = useState(false)
  
  const goNext = () => {
    setOpen(true);
  }

  const goBack = () => {
    setOpen(false);
  }

  const closeMenu = () => {
    goBack();
    close();
  }

  const items = item?.items?.filter(el => el.field_layout !== 'card')

  return (
    <>
      {hasChild ? (
        <ListItem disablePadding>
          <ListItemButton
            sx={{ px: 0 }}
            component="div"
            onClick={goNext}
          >
            <ListItemText primary={item.title} />
            <ListItemIcon sx={{justifyContent: 'flex-end'}}>
              <ArrowForwardIos fontSize="small" color="primary" />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        ) : (
          <>
          {item.description != null && <Typography  variant="bodyL" component="div" className={styles.dropdownTitle} dangerouslySetInnerHTML={{ __html: item.description }}/>}
          <ListItem disablePadding>
            <Link href={item.url} passHref legacyBehavior>
              <ListItemButton sx={{ px: 0 }} component="a" >
                <ListItemText primary={item.title} />
              </ListItemButton>
            </Link>
          </ListItem>
          </>
      )}
      
      {(hasChild && parentOpen) && (
        <Drawer
          open={open}
          ModalProps={{ keepMounted: false }}
          SlideProps={{ direction: 'left' }}
          onClose={closeMenu}
          sx={{
            display: { xs: 'block', xl: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <Box>
            <Container maxWidth={false} className={styles.header_drawer}>
              <Box className={styles.logo} display='flex' alignItems='center' justifyContent='space-between'>
                <Link href='/'><Logo /></Link>
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  edge="end"
                  onClick={closeMenu}
                >
                  <Close />
                </IconButton>
              </Box>

              <Box sx={{ mt: 24 }} className={styles.mainMenuPrimary}>

                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ p: 0 }}
                    component="a"
                    onClick={goBack}
                  >
                    <ListItemIcon sx={{minWidth: 32}}>
                      <ArrowBackIos fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={item.title} primaryTypographyProps={{fontWeight: 500}} />
                  </ListItemButton>
                </ListItem>              
                
                <Link href={item.url}>
                  <Typography variant="bodyL" color="primary" fontWeight={500} ml={32} mb={4} display="block">{t("discoverMore")}</Typography>
                </Link>

                <List>
                  {items ? items.map((el, i) => <MenuMobileElement key={i} item={el} close={close} parentOpen={parentOpen}/>) : null}
                </List>
              </Box>

              <Button
                className={styles.buttonDrawer}
                id="header-mobile-contact-us"
                variant="contained"
                href={t("contact_us_url")}
              >{t('header.button')}</Button>

            </Container>
          </Box>
        </Drawer>
      )}
    </>
  )
}

export const renderMainMenu = (menu) => menu?.map((item) => <MenuItemPrimary key={item.id} item={item} /> )

export const renderMainMenuMobile = (menu, close, parentOpen) => menu?.filter(el => el.field_layout !== 'card').map((item, index) => <MenuMobileElement key={index} item={item} close={close} parentOpen={parentOpen}/> )

export const renderFooterMenu = (menu) => {
  return (

    menu.map((item, index) =>  {
      
      let external = item.options.external && !item.url.includes(process.env.NEXT_PUBLIC_BASE_URL);
      
      return(
        <Typography key={index} variant="bodyM" component="a" href={item.url} target={external ? '_blank' : '_self'} mb={4}>
          {item.title} {external && <ArrowOutward />}
        </Typography>
      )
    })
  )
}

export const renderCopyrightMenu = (menu) => menu?.map((item, index) => <Link key={index} href={item.url} passHref>{item.title}</Link> )