import styles from "@components/Common/Header/Header.module.scss";
import Row from "@components/Modules/Utils/Row/Row";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { MenuItemModel } from "../Menu.model";
import { MenuItemCard } from "../MenuItemCard";

export const LayoutThreeCards: React.FC<{item: MenuItemModel}> = ({item}): JSX.Element => {
  
  const cards: MenuItemModel[] = item?.items.filter((el: MenuItemModel): boolean => el?.field_layout === 'card');
  
  return (
    <div className={styles.dropdownMenu}>
      <Container maxWidth={false}>
        <Row spacing={0} justifyContent={'flex-start'}>
          
          {cards?.map((card: MenuItemModel): JSX.Element => (
            <Grid key={card.id} item lg={4}>
              <MenuItemCard item={card} />
            </Grid>
          ))}

        </Row>
      </Container>
    </div>
  )
}
export default LayoutThreeCards