import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import styles from "@components/Common/Header/Header.module.scss";
import Row from "@components/Modules/Utils/Row/Row";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Link from "next/link";
import React from "react";
import { MenuItemModel } from "../Menu.model";

const LayoutExpanded: React.FC<{item: MenuItemModel}> = ({item}) => {

  const generateCols = () => {
    let menu = [[], [], []];
    let col = 0;

    for (let i = 0; i < item?.items?.length; i++) {
      if (item?.items[i].expanded) col++;
      menu[col].push(item?.items[i]);
    }
    return menu;
  };

  return (
    <div className={styles.dropdownMenu}>
      <Container maxWidth={false}>
        <Row spacing={0} justifyContent={'space-between'}>

        {generateCols().map((col, index) => {
          return (
            <Grid key={`Col_${index}`} item lg={4} position="relative" borderRight={index < 2 ? '1px solid #DFE1E6' : null}>
              <List disablePadding>
                {col.length > 0 && col.map((el, i) => {
                  return (
                    <React.Fragment key={`ListItem_${index}_${i}`}>
                      {el.description != null && <Typography variant="bodyL" component="div" className={styles.dropdownTitle} dangerouslySetInnerHTML={{ __html: el.description }} />}
                      <ListItem disablePadding style={{ position: 'static' }}>
                        <Link href={el.url} passHref legacyBehavior>
                          <ListItemButton component="a" style={{ paddingLeft: 0 }}>
                            <ListItemText primary={el.title} />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </List>
            </Grid>
          );
        })}

        </Row>
      </Container>
    </div>
  )
}

export default LayoutExpanded