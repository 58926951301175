import styles from "@components/Common/Header/Header.module.scss";
import Row from "@components/Modules/Utils/Row/Row";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { MenuItemModel } from "../Menu.model";
import { MenuItemCard } from "../MenuItemCard";
import { MenuItemSecondary } from "../MenuItemSecondary";

export const LayoutJournal: React.FC<{item: MenuItemModel}> = ({item}) => {
  
  const items: MenuItemModel[] = item?.items.filter(el => el?.field_layout !== 'card')
  const cards: MenuItemModel[] = item?.items.filter(el => el?.field_layout === 'card')
  return (
    <div className={styles.dropdownMenu}>
      <Container maxWidth={false}>
        <Row spacing={0} justifyContent={'space-between'}>
          
          {cards?.map(card => (
            <Grid key={card.id} item lg={4}>
              <MenuItemCard item={card} />
            </Grid>
          ))}

          <Grid item lg={4} position="relative" ml={'auto'}>
            
            {items &&
              <Typography variant="bodyL" className={styles.dropdownTitle}>
                {items[0]?.description}
              </Typography>
            }

            <List disablePadding>
              {items?.map((item) => <MenuItemSecondary key={item.id} item={item} />)}
            </List>
          </Grid>

        </Row>
      </Container>
    </div>
  )
}
export default LayoutJournal